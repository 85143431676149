<template>
  <EmptyState
    message="Carregue em uma categoria"
    height="calc(100vh - 150px)"
  />
</template>

<script>
import EmptyState from "@/components/EmptyState.vue";

export default {
  mixins: [],

  components: {
    EmptyState,
  },

  props: {},

  data() {
    return {};
  },

  methods: {},

  computed: {},
};
</script>
